(() => {
  "use strict";

  // ==========================================================================
  //  Inview
  // ==========================================================================
  $(".js-inview").on("inview", function () {
    // js-inview--doneクラスを付与＆カスタムイベントinviewResetを実行
    $(this).addClass("js-inview--done");
  });

  document.addEventListener("DOMContentLoaded", function () {
    // ==========================================================================
    // smoothscroll
    // ==========================================================================
    $(function () {
      $('a[href^="#"]').click(function () {
        var href = $(this).attr("href");
        if (href == "#") {
          var adjust = 0;
        } else {
          var adjust = $(".js-header").height();
        }
        var speed = 600;
        var target = $(href == "#" || href == "" ? "html" : href);
        var position = target.offset().top - adjust;
        $("body,html").animate({ scrollTop: position }, speed, "easeOutCubic");
        return false;
      });
    });

    // ==========================================================================
    // js-page-top
    // ==========================================================================
    var pagetop = $(".js-page-top");
    var pagetopLink = pagetop.find(".js-page-top__link");
    var pagetopMargin = 40;
    $(window).on("load scroll resize", function () {
      var linkPotision = pagetop.offset().top + pagetop.height() + pagetopMargin;
      // console.log('linkPotision = '+linkPotision);
      var scrollTop = $(this).scrollTop();
      var scrollBottom = scrollTop + $(this).height(); //window下部のPosition
      // console.log(scrollBottom);
      if (scrollBottom >= linkPotision) {
        pagetop.removeClass("-fixed");
      } else {
        pagetop.addClass("-fixed");
      }
      if (scrollTop >= 100) {
        pagetop.addClass("-visible");
      } else {
        pagetop.removeClass("-visible");
      }
    });
    // ==========================================================================
    // メニュー
    // ==========================================================================
    const openMenuButtons = document.querySelectorAll(".js-drawer-button");
    const thisMenu = document.querySelector(".js-drawer");
    const body = document.getElementsByTagName("body");

    openMenuButtons.forEach((openButton) => {
      openButton.addEventListener("click", (e) => {
        updateMenuButtons();
      });
    });

    function updateMenuButtons() {
      // ラベル名：aria-label
      const menuOpenTxt = "メニューを開く";
      const menuCloseTxt = "メニューを閉じる";
      const isLabel02 = openMenuButtons[0].getAttribute("aria-label") === menuOpenTxt;

      // 要素のグループ：aria-expanded
      const isExpanded02 = openMenuButtons[0].getAttribute("aria-expanded") === "true";

      // 検索メニュー：aria-hidden
      const isHidden02 = thisMenu.getAttribute("aria-hidden") === "true";
      // ラベル名：aria-label
      openMenuButtons[0].setAttribute("aria-label", isLabel02 ? menuCloseTxt : menuOpenTxt);

      // 要素のグループ：aria-expanded
      openMenuButtons[0].setAttribute("aria-expanded", isExpanded02 ? false : "true");

      // 検索メニュー：aria-hidden
      thisMenu.setAttribute("aria-hidden", isHidden02 ? false : "true");

      if (isHidden02) {
        console.log("modal true");
        body[0].classList.remove("-open-drawer");
      } else {
        console.log("modal false");
        body[0].classList.add("-open-drawer");
      }

      // コンテンツ上部に移動
      thisMenu.scrollTo(0, 0);
    }
  });
  // END

  // ==========================================================================
  // vw/vhスクロールバー問題の解消
  // ==========================================================================
  const setVw = function () {
    const vw = document.documentElement.clientWidth / 100;
    document.documentElement.style.setProperty("--vw", `${vw}px`);
  };
  $(document).ready(function () {
    setVw();
  });
  window.addEventListener("resize", setVw);

  // ==========================================================================
  // js-toggle
  // ==========================================================================
  if ($(".js-toggle-btn").length > 0) {
    var $toggle_btn = $(".js-toggle-btn"),
      $toggle_con = $(".js-toggle-content"),
      class_active = "is_active";

    $toggle_btn.on("click", function () {
      $(this).toggleClass(class_active);
      $(this).next(".js-toggle-content").slideToggle(200);
    });
  }

  // ==========================================================================
  // js-cookie
  // ==========================================================================
  window.addEventListener("DOMContentLoaded", (event) => {
    const docCookie = document.cookie;
    const cookieElement = document.getElementById("js-cookie");
    const cookieAccept = document.getElementById("js-cookie-accept");
    const cookieDeny = document.getElementById("js-cookie-deny");
    const cookieConsentDate = 60 * 60 * 24 * 365;
    if (cookieElement != null) {
      if (docCookie.split(";").some((item) => item.trim().startsWith("cookie_consent_status="))) {
        cookieElement.remove();
      } else {
        cookieAccept.addEventListener("click", () => {
          cookieElement.remove();
          document.cookie = "cookie_consent_status=true; path=/; max-age=" + cookieConsentDate;
          // alert(
          //   "同意後の処理は、各プロジェクト毎の案件に合わせて処理してください。"
          // );
        });
        cookieDeny.addEventListener("click", () => {
          cookieElement.remove();
          document.cookie = "cookie_consent_status=false; path=/; max-age=" + cookieConsentDate;
          // alert(
          //   "拒否後の処理は、各プロジェクト毎の案件に合わせて処理してください。"
          // );
        });
      }
    }
    if (docCookie.split(";").some((item) => item.includes("cookie_consent_status=true"))) {
      console.log("cookie同意してます");
    }
    if (docCookie.split(";").some((item) => item.includes("cookie_consent_status=false"))) {
      console.log("cookie拒否してます");
    }
  });
})();

function setCookieConsent(cookieValue) {
  var name = "cookieConsent"; // クッキーの名前
  var value = cookieValue; // クッキーの値
  var period = 30; // 有効期限日数
  // 有効期限の作成（有効期限がないと、ブラウザを閉じた時点で cookieは無効）
  var nowtime = new Date().getTime();
  var clear_time = new Date(nowtime + 60 * 60 * 24 * 1000 * period);
  var expires = clear_time.toGMTString();
  // クッキーの発行（書き込み）
  document.cookie = name + "=" + escape(value) + "; expires=" + expires + "; path=/";
  console.log("cookie「" + name + "」に「" + cookieValue + "」を格納");

  if (value == "true") consentGrantedAdStorage();
}
